// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agbs-js": () => import("./../../../src/pages/agbs.js" /* webpackChunkName: "component---src-pages-agbs-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-geschaeftskunden-js": () => import("./../../../src/pages/geschaeftskunden.js" /* webpackChunkName: "component---src-pages-geschaeftskunden-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kaffeebar-js": () => import("./../../../src/pages/kaffeebar.js" /* webpackChunkName: "component---src-pages-kaffeebar-js" */),
  "component---src-pages-laden-js": () => import("./../../../src/pages/laden.js" /* webpackChunkName: "component---src-pages-laden-js" */),
  "component---src-pages-roesterei-js": () => import("./../../../src/pages/roesterei.js" /* webpackChunkName: "component---src-pages-roesterei-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

